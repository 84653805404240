import React from "react";

export default {
  title: "Planeten Jorda",
  text: `
<P>
En gang var vi krigere som levde i denne vakre verden og vi forsøkte
å finne ut hvem vi var og hvor vi var. Vi var naturens Jord folk og
levde vesentlig av <b>RØD</b>
energi, og vi la under oss hele verden på
<b>RØD</b> energi.
<br />I dag trenger vi en annen type energi skal vi klare å overleve
en Homo Sapiens tilstand. Vi trenger å bruke andre farge energier
enn <b>RØD</b> energi, vi trenger
<b>INDIGO</b> energi for i det
hele tatt å kunne ta innover oss det Universale vibrasjons språket.
Og den samme kraften som LIVET inneholder finnes i vår menneskelige
kropp, og den kraften er den sterkeste energi ressurs vi har på
Jorda.
<br />
Og vårt mål i Colour Energy er å introdusere disse menneske vennlige
ressurser til Jordas folk, fordi så langt vi enn er kommet i vår
utdannelse som mennesker, så har vi likevel unnlatt å utdanne folk i
det viktigste faget FARGENE.
</P>
<p>
<b>
Uten fargene er det vanskelig å forstå hvorfor regnbuen er der og
hvorfor lyset inneholder alle fargene?
</b>
<b>
Vi trenger fargene for å forstå hvordan vi selv er og ikke minst
hvordan vi bruker de ressursene fargene har.
</b>
<b>
Vi trenger å få vite HVEM VI ER. Slik at vi kan bli til den vi
ønsker å være.
</b>
</P>
    `,
};
