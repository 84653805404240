import React from "react";
import { colors } from "../colors";

const fontSize = 75;

export const Title: React.FC<{
  onlyBlue?: boolean;
  onlyGreen?: boolean;
  subtitle?: string;
}> = ({ children, onlyBlue, onlyGreen, subtitle }) => (
  <>
    {subtitle && <h5 className="subt">{subtitle}</h5>}
    <div className="container">
      <h1 className="title">{children}</h1>
    </div>
    <style jsx>{`
      .subt {
        margin: 0;
        height: 0;
        transform: translateY(10px);
        letter-spacing: 4px;
        text-transform: uppercase;
        color: ${colors.rainbow.blue};

        ${onlyGreen && `color: ${colors.rainbow.green}`}
      }
      .title {
        text-align: center;

        font-size: ${fontSize || "100"}px;
        line-height: ${fontSize ? `${fontSize + 2}px` : "100px"};
        font-weight: 900;
        -webkit-text-stroke: 4px white;

        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);

        margin: 0;

        color: ${colors.rainbow.blue};
        font-family: Montserrat, sans-serif;

        text-transform: uppercase;

        animation: change-colors ${`${5 * 2}s`} ease-in infinite;
        ${onlyBlue && `animation: none;`};
        ${onlyGreen && `animation: none; color: ${colors.rainbow.green};`};
      }

      .container {
        overflow: hidden;
        margin: 0;
        padding: 30px 0;

        animation: bounce ${`${5 * 7}s`} ease-in infinite;
      }

      @keyframes change-colors {
        ${`${(100 / 14) * 0}%`} {
          color: ${colors.rainbow.violet};
        }
        ${`${(100 / 14) * 1}%`} {
          color: ${colors.rainbow.violet};
        }

        ${`${(100 / 14) * 2}%`} {
          color: ${colors.rainbow.red};
        }

        ${`${(100 / 14) * 3}%`} {
          color: ${colors.rainbow.red};
        }

        ${`${(100 / 14) * 4}%`} {
          color: ${colors.rainbow.orange};
        }

        ${`${(100 / 14) * 5}%`} {
          color: ${colors.rainbow.orange};
        }

        ${`${(100 / 14) * 6}%`} {
          color: ${colors.rainbow.yellow};
        }

        ${`${(100 / 14) * 7}%`} {
          color: ${colors.rainbow.yellow};
        }
        ${`${(100 / 14) * 8}%`} {
          color: ${colors.rainbow.green};
        }
        ${`${(100 / 14) * 9}%`} {
          color: ${colors.rainbow.green};
        }

        ${`${(100 / 14) * 10}%`} {
          color: ${colors.rainbow.blue};
        }

        ${`${(100 / 14) * 11}%`} {
          color: ${colors.rainbow.blue};
        }

        ${`${(100 / 14) * 12}%`} {
          color: ${colors.rainbow.indigo};
        }

        ${`${(100 / 14) * 13}%`} {
          color: ${colors.rainbow.indigo};
        }

        ${`${(100 / 14) * 14}%`} {
          color: ${colors.rainbow.violet};
        }
      }
      @keyframes bounce {
        ${`${(100 / 14) * 0}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 1}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 2}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 3}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 4}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 5}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 6}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 7}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 8}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 9}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 10}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 11}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 12}%`} {
          transform: scale(1);
        }
        ${`${(100 / 14) * 13}%`} {
          transform: scale(1.05);
        }
        ${`${(100 / 14) * 14}%`} {
          transform: scale(1);
        }
      }
      @media screen and (max-width: 1440px) {
        .title {
          font-size: 48px;
          line-height: 50px;
          -webkit-text-stroke: 2px white;
          text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        }

        .container {
          max-width: 100%;
        }
      }

      @media screen and (max-width: 900px) {
        .container {
          max-width: 80%;
        }
      }
    `}</style>
  </>
);
