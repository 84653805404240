import React from "react";
import { colors } from "../colors";

export const Type = {
  LINK: "link",
  DOT: "dot",
};

const NavElement = ({
  type,
  href,
  label,
  color,
  selectedColor,
  white,
  contrast = false,
  compact = false,
}) => {
  return (
    <>
      <li>
        {type === Type.LINK ? (
          <a
            href={href}
            onClick={!href ? () => window.history.back() : undefined}
            className={"selected"}
            dangerouslySetInnerHTML={{ __html: label }}
          ></a>
        ) : (
          <span className="dot-separator" style={{ backgroundColor: color }} />
        )}
      </li>
      <style jsx>{`
        .selected {
          background-color: ${selectedColor};
          color: ${contrast ? colors.indigo : "#fff"};
          padding: 4px 16px;
          border-radius: 6px;
          cursor: pointer;
        }

        li {
          display: flex;
          padding: 6px 8px;
        }

        a {
          font-size: ${compact ? "13px" : "18px"};
          color: ${white ? "#fff" : colors.indigo};
          opacity: 0.75;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 600;
        }

        a:hover {
          opacity: 1;
        }

        .dot-separator {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin: 0 8px;
        }
      `}</style>
    </>
  );
};

export const Nav = ({ white, menu, compact = false }) => {
  return (
    <nav>
      <ul>
        {menu.map((props) => (
          <NavElement {...props} white={white} compact={compact} />
        ))}
      </ul>

      {/* language=CSS */}
      <style jsx>{`
        :global(body) {
          margin: 0;
          font-family: Montserrat, sans-serif;
        }

        nav {
          position: relative;
          text-align: center;
          box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.16);
          z-index: 10;
        }

        ul {
          display: flex;
          margin: 0;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
        }

        nav > ul {
          padding: ${compact ? "8px" : "24px"} 16px;
        }
      `}</style>
    </nav>
  );
};
