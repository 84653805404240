/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./App.css";
import Image from "./components/image";
import allAboutColoursSrc from "./all-about-colors.jpg";
import educationSrc from "./education_2.png";
import educationBlueSrc from "./education_blue.png";
import personalityBlueSrc from "./personality-test-blue.png";
import personalityGreenSrc from "./personality-test-green.png";
import personalityIndigoSrc from "./personality-test-indigo.png";
import personalityOrangeSrc from "./personality-test-orange.png";
import personalityRedSrc from "./personality-test-red.png";
import personalityVioletSrc from "./personality-test-violet.png";
import personalityYellowSrc from "./personality-test-yellow.png";
import educationBackgroundSrc from "./bg-yellow2-texture.jpeg";
import educationBlueBackgroundSrc from "./education_blue_bg.jpeg";
import collaborativeHeaderSrc from "./collaborative-header.jpg";
import shopHeaderSrc from "./shop-header.jpg";
import contactImageSrc from "./contact-image.jpg";
import { Title } from "./components/title";
import { Slider } from "./components/Slider";
import { colors } from "./colors";
import { Nav, Type } from "./components/Nav";
import { useLanguage } from "./LanguageContext";

const fontSize = 100;

function SliderPage() {
  const personalityImages = [
    personalityBlueSrc,
    personalityGreenSrc,
    personalityIndigoSrc,
    personalityOrangeSrc,
    personalityRedSrc,
    personalityVioletSrc,
    personalityYellowSrc,
  ];
  const [personlityIndex, setPersonalityIndex] = useState(0);
  const { lang, translate } = useLanguage();
  return (
    <div className="App">
      <Nav
        menu={[
          {
            type: Type.LINK,
            label: lang === "no" ? "Tilbake" : "Back",
            href: `/?lang=${lang}`,
            selectedColor: colors.blue,
          },
        ]}
        white
        compact
      />
      <div className="slider-wrapper">
        <Slider />
      </div>
      <style jsx>{`
        .slider-wrapper {
          margin: 60px 0;
        }
        @media screen and (max-width: 1200px) {
          .slider-wrapper {
            margin: 20px 0;
          }
        }
      `}</style>
    </div>
  );
}

export default SliderPage;
