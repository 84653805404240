import manWithCloud from "../man-with-cloud-en.png";

export default {
  title: "HUMAN AND THE BRAIN",
  comic: manWithCloud,
  text: {
    //language=HTML
    left: `
            <p>Can the brain be our hope for the future? &nbsp;&nbsp;&nbsp;&nbsp; May be the intention is that we shall concentrate on the organ we know the least and which we need the most? &nbsp;&nbsp;&nbsp;&nbsp; <b> But why do we know so little about how our brain works?</b> 
                <br/> Is it because we don’t know ourselves good enough because we are like the brain divided into several parts that take care of the work that lies under the concept to keep us Alive.
                      And is it possible that we can help the brain? We may have misunderstood the secret about how and why our brain is created? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                      <b style="font-weight: 900;">And one question; what are we really going to use our brains for?</b>
                    </P>
        `,
  },
};
