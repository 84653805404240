import React from "react";
import { colors } from "../colors";
import iconArrowBottomSrc from "../icon-arrow-bottom.svg";
import humanEvolutionSrc from "../human-evolution.png";
import manSrc from "../man.png";

const HumanEvolution = ({ onInteraction }) => {
  const onClick = (e) => {
    if (!onInteraction) return;

    e.preventDefault();
    onInteraction(true);
  };

  return (
    <>
      <div className="container-a">
        <div className="canvas">
          <img className="human-evolution" src={humanEvolutionSrc} />
          <a onClick={onClick} href="#">
            <img className="standing-man" src={manSrc} />
          </a>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>{`
        a {
          cursor: pointer;
        }

        .container-a {
          width: 100%;
          position: relative;
          margin-top: 50px;
        }

        .canvas {
          width: 100%;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: flex-end;
          border-radius: 4px;
          transform: translateX(-20px);
        }

        .human-evolution {
          width: 70%;
          height: 450px;
          margin-top: 0;
          margin-bottom: 0;
        }

        .standing-man {
          height: 455px;
          transform: translateX(-110px) translateY(30px);
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      `}</style>
    </>
  );
};

export default HumanEvolution;
