import manWithCloud from "../man-with-cloud-en.png";

export default {
  title: "MENNESKET OG HJERNEN",
  comic: manWithCloud,
  text: {
    //language=HTML
    left: `
<p>Kan hjernen være vårt HÅP for fremtiden? &nbsp;&nbsp;&nbsp;&nbsp; Kan meningen være at vi skal konsentrere oss om det organet
som vi vet minst om og som vi trenger mest? &nbsp;&nbsp;&nbsp;&nbsp; <b>Og hvorfor vet vi så lite om vår hjerne?</b>
Er det fordi vi ikke kjenner godt nok til oss selv, fordi vi
er som hjernen delt inn i flere forskjellige avdelinger som
tar seg av forskjellige deler av det arbeidet som ligger under
begrepet om å: holde LIV i oss. Er det mulig at vi kan hjelpe hjernen, for kan vi ha
misforstått <u>noe</u> av hjemligheten om hvordan og hvorfor vår
hjerne er skapt? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b style="font-weight: 900;">Og hva vi egentlig skal bruke vår hjerne til?</b></P>
        `,
  },
};
