import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import { Title } from "./title";

const AuthPanel = ({ onAuthenticated }) => {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const onChange = useCallback(
    (event) => setPassword(event.target.value),
    [setPassword]
  );
  const onClick = useCallback(() => {
    if (password !== "estaban") {
      setError(true);
      return;
    }

    setError(false);
    Cookies.set("authenticated", "true", { expires: 7 });
    onAuthenticated();
  }, [password, setError, onAuthenticated]);
  return (
    <div className="panel">
      <Title>Colour Energy World</Title>
      <input
        className={error ? "error" : undefined}
        type="password"
        id="pass"
        name="password"
        minLength={8}
        onChange={onChange}
      />
      {error && <span className="error">The password is incorrect</span>}
      <button type="submit" onClick={onClick}>
        Enter Colour Energy World
      </button>
      {/* language=CSS */}
      <style jsx>{`
        .panel {
          display: flex;
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        h1 {
          text-align: center;
        }

        input {
          border-radius: 4px;
        }

        input[type="password"] {
          margin-top: 32px;
          font-size: 24px;
          min-width: 200px;
          padding: 8px 24px;
          border: solid 1px rgba(0, 0, 0, 0.7);
        }

        input[type="password"].error {
          border-color: red;
        }

        .error {
          padding: 4px 0;
          font-size: 20px;
          line-height: 28px;
          color: red;
        }

        button[type="submit"] {
          border: none;
          background-color: green;
          color: white;
          padding: 15px 32px;
          text-align: center;
          border-radius: 2px;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          margin: 16px 2px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default AuthPanel;
