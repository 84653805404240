export default {
  title: "Homo Sapiens",
  motto: "ARE WE THE LAST PEOPLE?",
  text: `
<P>
Our purpose with the Colour Energy concept is to tell how important it is to learn and understand what the Human resources and qualities are about. As these qualities contain more than what we have understood as of today, because they have a far greater influence and impact  on how we understand and use the education the Schools offer children and youth today.
<B>
  Because it is <u>YOU</u> as a person that creates yourself and your surroundings.
</B>
 </P>
<P>
Personal knowledge and understanding is to know WHO YOU ARE. And this knowledge about you is far more important than the school system has understood. One should learn about the concepts of the COLOURS, what we call the  COLOUR LANGUAGE  before learning the letters of a  normal alphabet, because letters that only make words but do not tell what the words mean, has no value either. Because a word has no value if one does not connected one’s consciousness to the WORD.
 </P>
<P>
<B>
Only when WORDS become a concept and you are the concept, can word have a meaning that fills the space the WORD is meant for.
</B>
 </P>
`,
};
