export default {
  title: "Solen",
  text: `
    <p>Dette bildet av den kjente maleren Edward Munch er absolutt det
    beste bildet når vi skal presentere Solen, Lyset og Fargene, da
    bildet innehar alt vi ønsker å fortelle om. Fordi tilhørligheten til Universet, Solen og vår planet Jorden er
    alt vi trenger for å holde oss i live, og det skinenede lyset fra
    Solen gjør oss i stand til å se vår verden i farger.</p>
    <p>
    Men for at vi skal kunne komme oss videre som mennesker på vår skala
    for evolusjons utvikling, så må vi nok forandre oss. Vi står stille som mennesker mens vår jord blir misbrukt, og den SOL
    energien som er vår redning blir gjort om til solpaneler, og det er bra! <b>Men vi glemmer å fullføre vår egen evolusjon som et menneske.</b> Vi bør gjøre <u>noe,</u> fordi den meste spennende utviklingen for oss
    mennesker ligger foran oss. Men kun hvis vi begynner å lære oss selv bedre og mer å kjenne.
    </p>
`,
};
