import React, { useState } from "react";
import { colors } from "../colors";
import HumanEvolution from "./human-evolution";
import manAndBrainSrc from "../man-and-brain.jpeg";
import wideSunSrc from "../wide-sun.jpg";
import worldMapIndigoSrc from "../world-map-indigo.png";
import { t } from "../utils";
import Image from "./image";
import iconArrowBottomSrc from "../icon-arrow-bottom.svg";
import { useLanguage } from "../LanguageContext";
import { useHistory } from "react-router-dom";

const useSliderTitle = (sliderNo: number) => {
  const { translate } = useLanguage();
  switch (sliderNo) {
    case 0:
      return translate("page.home.sun.title");
    case 1:
      return translate("page.home.world.title");
    case 2:
      return translate("page.home.homoSapiens.title");
    case 3:
      return translate("page.home.brain.title");
  }
};

export const Slider = () => {
  const [slider, setSlider] = useState(0);
  const sliderTitle = useSliderTitle(slider);
  const { translate } = useLanguage();
  const history = useHistory();

  return (
    <>
      <div className="slider">
        <div className="slider-title">{sliderTitle}</div>
        <div
          className={`slider__slide ${slider === 0 && "slider__slide--active"}`}
        >
          <div
            className="slide__content single"
            dangerouslySetInnerHTML={{
              __html: translate("page.home.sun.text"),
            }}
          />
          <img
            className="slide__image"
            src={wideSunSrc}
            style={{ height: 500 }}
          />
          <a
            className="slide__nav slide__nav--next"
            onClick={() => setSlider(1)}
          >
            <i className="arrow-right"></i>
          </a>
        </div>
        <div
          className={`slider__slide ${slider === 1 && "slider__slide--active"}`}
        >
          <div
            style={{ color: colors.indigo }}
            className="slide__content single"
            dangerouslySetInnerHTML={{
              __html: translate("page.home.world.text"),
            }}
          />
          <img
            className="slide__image"
            src={worldMapIndigoSrc}
            style={{
              height: 500,
              backgroundColor: colors.indigo,
              objectFit: "contain",
            }}
          />
          <a
            className="slide__nav slide__nav--prev"
            onClick={() => setSlider(0)}
          >
            <i
              className="arrow-right"
              style={{ backgroundColor: colors.indigo }}
            ></i>
          </a>
          <a className="slide__nav" onClick={() => setSlider(2)}>
            <i
              className="arrow-right"
              style={{ backgroundColor: colors.indigo }}
            ></i>
          </a>
        </div>
        <div
          className={`slider__slide ${slider === 2 && "slider__slide--active"}`}
        >
          <div
            className="slide__content single"
            dangerouslySetInnerHTML={{
              __html: translate("page.home.homoSapiens.text"),
            }}
          />
          <HumanEvolution onInteraction={() => setSlider(3)} />
          <a
            className="slide__nav slide__nav--prev"
            onClick={() => setSlider(1)}
          >
            <i
              className="arrow-right"
              style={{ backgroundColor: colors.indigo }}
            ></i>
          </a>
          <a
            className="slide__nav slide__nav--next"
            onClick={() => setSlider(3)}
          >
            <i className="arrow-right"></i>
          </a>
        </div>
        <div
          className={`slider__slide ${slider === 3 && "slider__slide--active"}`}
        >
          <div
            className="slide__content single"
            dangerouslySetInnerHTML={{
              __html: translate("page.home.brain.text.left"),
            }}
          />
          <div className="comic">
            <img
              className="comic__left"
              src={translate("page.home.brain.comic")}
              alt="Man with cloud"
            />
            <Image
              className="comic__right"
              src={manAndBrainSrc}
              alt="Man and Brain"
            />
          </div>
          <a
            className="slide__nav slide__nav--prev"
            onClick={() => history.push("/")}
          >
            <i
              className="arrow-right"
              style={{ backgroundColor: colors.indigo }}
            ></i>
          </a>
          {/* <a className="slide__nav slide__nav--next">
        <i className="arrow-right"></i>
      </a> */}
        </div>
      </div>
      <style jsx>{`
        .comic {
          position: relative;
          padding-top: 20px;
          padding-left: 300px;
          width: 100%;
          object-fit: contain;
          margin-top: 70px;
        }

        .comic__left {
          position: absolute;
          left: 175px;
          width: calc(500 / 542 * 398px);
          height: 500px;
        }

        .comic__right {
          height: 500px;
          object-fit: contain;
        }

        .slider {
          margin-top: 20px;
          position: relative;
          width: 100%;
          background-color: #fff;
        }

        .slider__slide {
          display: none;
        }

        .slider__slide--active {
          display: block;
        }

        .slide__title {
          text-transform: uppercase;
          color: ${colors.red};
          font-size: 26px;
        }

        .slide__image {
          display: block;
          width: 100%;
          object-fit: cover;
          margin-bottom: 0;
          height: 350px;
          margin-top: 80px;
        }

        .slide__content {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: justify;
          padding: 10px 40px;
          color: ${colors.red};
          font-weight: 600;
          font-size: 20px;
        }

        .slide__content:not(.single) {
          height: 250px;
        }

        .slide__content:not(.single) p {
          margin-bottom: 0;
          width: 50%;
          padding: 0 10px;
        }


        @media screen and (max-width: 1200px) {
          :global(.slider-wrapper) {
            margin: 10px 0;
          }
          .slide__title {
            font-size: 20px;
          }
          .slide__content {
            font-size: 16px;
            height: auto;
          }
          .slide__image {
            margin-top: 10px;
          }
        }

        .slide__content.single p {
          margin-bottom: 0;
        }

        .slide__nav {
          position: absolute;
          bottom: 30%;
          margin: auto 0;
          right: 24px;
          background-color: #fff;
          border-radius: 50%;
          padding: 5px;
          cursor: pointer;
        }

        .slide__nav--prev {
          transform: rotate(180deg);
          right: unset;
          left: 24px;
        }

        .arrow-right {
          display: inline-block;
          width: 50px;
          height: 50px;
          transform: rotate(-90deg) translateX(-2px);
          mask: url(${iconArrowBottomSrc});
          -webkit-mask: url(${iconArrowBottomSrc});
          mask-size: 50px;
          -webkit-mask-size: 50px;
          mask-position: center;
          background-color: ${colors.red};
        }

        .slider-title {
          position: absolute;
          font-size: 32px;
          text-transform: uppercase;
          font-weight: 700;
          color: red;
          left: 0;
          right: 0;
          top: -30px;
        }
      `}</style>
    </>
  );
};
