export default {
  title: "Homo Sapiens",
  motto: "ER VI DE SISTE MENNESKENE?",
  text: `
<P>
Vår hensikt med Colour Energy konseptet er å fortelle om hvor viktig
det er å lære og å forstå hva de menneskelige ressursenes kvaliteter
handler om, da disse kvalitetene inneholder mer enn det vi har
forstått pr i dag, fordi de har en langt større innflytelse og
påvirkning på hvordan vi forstår og bruker den utdannelsen
skoleverket tilbyr barn og ungdom i dag.
<B>
For det er <u>DU</u> som person som skaper deg selv og de
omgivelser Du har rundt deg.
</B>
 </P>
<P>
Personlig viten og forståelse er å vite HVEM DU ER. Og denne
kunnskapen om deg selv er langt viktigere enn det skole systemet har
forstått. Man bør lære seg om begrepene til FARGENE, det vi kaller
FARGE SPRÅKET før man lærer seg bokstavene i et vanlig alfabet,
fordi bokstaver som kun lager ord, men ikke forteller hva ordet
betyr, har heller ingen verdi. Fordi et ord har ingen verdi hvis man
ikke har koblet sin bevissthet til ORDET.
 </P>
<P>
<B>
Kun når ORDENE blir et begrep og du er begrepet, kan ordet ha en
mening som fyller den plassen ORDET er ment for.
</B>
 </P>
`,
};
