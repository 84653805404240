import React from "react";
import { useLanguage } from "../LanguageContext";

export const LangSwitcher = () => {
  const { lang } = useLanguage();
  return (
    <>
      <button
        type="button"
        className="lang-switcher"
        onClick={() => {
          window.location.href = `/?lang=${lang === "no" ? "en" : "no"}`;
        }}
      >
        Switch to {lang === "no" ? "English" : "Norwegian"}
      </button>
      <style jsx>{`
        .lang-switcher {
          position: absolute;
          top: 20%;
          left: 0;
          padding: 5px 20px;
          background-color: #fff;
          border: none;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
          font-size: 14px;
          font-weight: 400;
          transform-origin: left;
          transform: rotate(-90deg) translateY(50%);
          appearance: none;
          outline: none;
          cursor: pointer;
          z-index: 100;
        }
      `}</style>
    </>
  );
};
