import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import AuthPanel from "./components/auth-panel";

export const withAuth = (WrappedPage) => {
  const WithAuth = (props) => {
    const [allow, setAllow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      const isAuthenticated =
        Cookies.get("authenticated") === "true" ||
        process.env.NODE_ENV === "development";
      setAllow(isAuthenticated);
      setLoaded(true);
    }, [setAllow, setLoaded]);
    const onAuthenticated = useCallback(() => {
      setAllow(true);
    }, [setAllow]);

    if (!loaded) return <div />;
    return allow ? (
      <WrappedPage {...props} />
    ) : (
      <AuthPanel onAuthenticated={onAuthenticated} />
    );
  };

  return WithAuth;
};
