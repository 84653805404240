/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./App.css";
import Image from "./components/image";
import allAboutColoursSrc from "./all-about-colors.jpg";
import educationSrc from "./education_2.png";
import educationBlueSrc from "./education_blue.png";
import personalityBlueSrc from "./personality-test-blue.png";
import personalityGreenSrc from "./personality-test-green.png";
import personalityIndigoSrc from "./personality-test-indigo.png";
import personalityOrangeSrc from "./personality-test-orange.png";
import personalityRedSrc from "./personality-test-red.png";
import personalityVioletSrc from "./personality-test-violet.png";
import personalityYellowSrc from "./personality-test-yellow.png";
import educationBackgroundSrc from "./bg-yellow2-texture.jpeg";
import educationBlueBackgroundSrc from "./education_blue_bg.jpeg";
import collaborativeHeaderSrc from "./collaborative-header.jpg";
import shopHeaderSrc from "./shop-header.jpg";
import contactImageSrc from "./contact-image.jpg";
import { Title } from "./components/title";
import { Slider } from "./components/Slider";
import { colors } from "./colors";
import { Nav, Type } from "./components/Nav";
import { useLanguage } from "./LanguageContext";
import { LangSwitcher } from "./components/LangSwitcher";
import { withAuth } from "./withAuth";

const fontSize = 100;

function App() {
  const personalityImages = [
    personalityBlueSrc,
    personalityGreenSrc,
    personalityIndigoSrc,
    personalityOrangeSrc,
    personalityRedSrc,
    personalityVioletSrc,
    personalityYellowSrc,
  ];
  const [personlityIndex, setPersonalityIndex] = useState(0);
  const { lang, translate } = useLanguage();
  return (
    <>
      <LangSwitcher />
      <div className="App">
        <Nav
          menu={[
            {
              type: Type.LINK,
              label: translate("history"),
              href: `https://colourenergyworld.com/${lang}/history`,
              selectedColor: colors.blue,
            },
          ]}
          white
          compact
        />
        <Title onlyBlue subtitle="ColourEnergyWorldInternational.com presents">
          Colour Energy World
        </Title>
        <div className="content" style={{ backgroundColor: "#fff" }}>
          <div className="inner-content">
            <div className="entry-item">
              <a
                className="entry-link"
                href={`https://${lang}.colourenergyeducation.com/`}
              >
                Colour Energy Education
              </a>
              <img
                src={educationBlueSrc}
                className="entry-image with-background-blue"
              />
            </div>
            {/* <div style={{ transform: "translateY(-20px)" }}>
            <div className="App-logo-container">
              <div className="App-logo" />
            </div>
          </div> */}
            <div className="entry-item">
              <a
                className="entry-link"
                href={translate("personalityTest.href")}
              >
                {translate("personalityTestButton")}
              </a>
              <div className="image-wrapper">
                <img
                  src={personalityImages[personlityIndex]}
                  className="entry-image"
                  onClick={() => setPersonalityIndex((personlityIndex + 1) % 7)}
                />
                <div className="image-content">
                  <h3 className="image-title">
                    {translate("personalityTest.title")}
                  </h3>
                  <p className="image-text">
                    {translate("personalityTest.text")}
                  </p>
                </div>
              </div>
            </div>
            <div className="entry-item">
              <a
                className="entry-link"
                href={`https://${lang}.colourenergyeducation.com/?rt=11`}
              >
                {lang === "no" ? "Selv opplæring" : "Self training"}
              </a>
              <img src={educationSrc} className="entry-image with-background" />
            </div>
          </div>
          <div className="slider-link-container">
            <p className="slider-text">
              {lang === "no"
                ? `Vårt formål er FARGER – JORDA – Homo Sapiens og vår hjerne.`
                : `OUR OBJECT IS COLOURS - EARTH - HOMO SAPIENS and our BRAIN`}
            </p>
            <a href="/slider" className="slider-link">
              {lang === "no" ? "Klikk her" : "Click here"}
            </a>
          </div>
          <div className="inner-content">
            <div className="entry-item">
              <a
                className="entry-link"
                href={`https://self-education.colourenergyworld.com/${lang}/colour-adventure`}
              >
                {translate("colourAdventure")}
              </a>
              <img src={collaborativeHeaderSrc} className="entry-image" />
            </div>
            {/* <div style={{ transform: "translateY(-20px)" }}>
            <div className="App-logo-container">
              <div className="App-logo" />
            </div>
          </div> */}
            <div className="entry-item">
              {/* <a
                className="entry-link"
                href={`https://colourenergyshop.com/entry/?lang=${lang}&source=ce-international`}
              >
                Colour Energy Shop
              </a> */}
              <a
                className="entry-link"
                href={ 'en' === lang ? 'http://products.colourenergyshop.com/' : 'http://produkter.colourenergyshop.com/' }
              >
                Colour Energy Shop
              </a>
              <img src={shopHeaderSrc} className="entry-image" />
            </div>
            <div className="entry-item">
              <a
                className="entry-link"
                href={`https://colourenergyworld.com/${lang}/contact`}
              >
                {translate("contactUs")}
              </a>
              <img src={contactImageSrc} className="entry-image" />
            </div>
          </div>
        </div>
        <style jsx>{`
          .slider-link-container {
            margin: 20px 0;
          }

          .slider-link {
            display: block;
            text-decoration: underline;
            color: ${colors.rainbow.blue};
            font-size: 30px;
          }

          .slider-text {
            font-size: 26px;
            color: ${colors.rainbow.blue};
            margin: 0;
          }

          .title,
          .description {
            text-align: center;
          }

          .header .description {
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;
            font-size: 20px;
          }

          .row {
            max-width: 880px;
            margin: 80px auto 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          }

          .card {
            padding: 18px 18px 24px;
            width: 220px;
            text-align: left;
            text-decoration: none;
            color: #434343;
            border: 1px solid #9b9b9b;
          }

          .card:hover {
            border-color: #067df7;
          }

          .card h3 {
            margin: 0;
            color: #067df7;
            font-size: 18px;
          }

          .card p {
            margin: 0;
            padding: 12px 0 0;
            font-size: 13px;
            color: #333;
          }

          .title-subsection {
            margin: 0;
            text-align: center;
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            text-transform: uppercase;
          }

          :global(.img-header) {
            margin: 10px 0 !important;
            width: 100% !important;
            float: none !important;
          }

          .content {
            max-width: 100%;
            margin: 0 auto;
            padding: 0 20px;
          }

          .inner-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
          }

          .left-menu {
            display: block;
          }

          .menu-block {
            display: block;
            font-weight: 900;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            width: 450px;
            height: 450px;
          }

          .menu-block-img {
            filter: contrast(90%);
          }

          .banner {
            flex-grow: 0;
            display: block;
            width: 100%;
          }

          .banner-nav {
            appearance: none;
            border: none;
            background: none;
            font-size: 40px;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
          }

          .entry-link {
            display: flex;
            width: 100%;
            height: 40px;
            font-size: 20px;
            line-height: 25px;
            font-weight: 700;
            border-radius: 6px;
            background-color: ${colors.blue};
            text-decoration: none;
            color: #fff;
            padding: 20px 10px;
            align-items: center;
            justify-content: center;
          }

          .entry-image {
            width: 100%;
            height: 280px;
            object-fit: contain;
            margin-top: 10px;
            margin-bottom: 0;
            border-radius: 6px;
            box-shadow: 0 1px 4px 8px rgba(0, 0, 0, 0.12);
          }

          .entry-image.with-background {
            padding: 10px;
            background-image: url("${educationBackgroundSrc}");
          }
          .entry-image.with-background-blue {
            padding: 10px;
            background-image: url("${educationBlueBackgroundSrc}");
          }

          .image-wrapper {
            position: relative;
            text-align: center;
          }

          .image-content {
            position: absolute;
            top: 10px;
            left: 20px;
            text-align: left;
          }

          .image-title {
            font-size: 16px;
            line-height: 19px;
            color: ${colors.blue};
            margin-bottom: 1px;
            font-weight: 700;
          }

          .image-text {
            font-size: 14px;
            line-height: 16px;
            color: ${colors.blue};
            font-weight: 400;
          }

          .entry-item {
            display: flex;
            width: 380px;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
          }

          @media screen and (max-width: 900px) {
            .container {
              max-width: 80%;
            }
          }

          @media screen and (max-width: 1024px) {
            .entry-image {
              height: 200px;
            }
            .image-text {
              margin: 0;
            }
            .image-content {
              top: 0;
              left: 10px;
            }
            .entry-link {
              font-size: 16px;
            }
            .slider-text {
              font-size: 20px;
            }
            .slider-link {
              font-size: 20px;
            }
          }
        `}</style>
      </div>
    </>
  );
}

export default withAuth(App);
