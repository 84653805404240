export const colors = {
  red: "#fe0000",
  orange: "orange",
  yellow: "yellow",
  green: "#0e5b0b",
  blue: "#0E3ADB",
  indigo: "#4b0083",
  violet: "#cd0099",
  rainbow: {
    red: "#E3031B",
    orange: "#F29403",
    yellow: "#FFDD03",
    green: "#029031",
    indigo: "#4C2582",
    blue: "#0E3ADB",
    violet: "#93117D",
  },
};
