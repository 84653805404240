export default {
  title: "Sun",
  text: `
<P>This picture of famous painter Edward Munch is absolutely the
best picture when presenting the Sun, the Light and the Colours, then
the picture contains everything we want to tell you about.
Because the affinity of the Universe, the Sun and our planet Earth is
everything we need to stay alive, and the shining light from
The sun enables us to see our world in colour.</P>
<P>But in order for us to progress as human beings on our scale
for evolution, we must change. We stand still as human beings while our soil is abused, and the SUN
the energy that is our salvation is turned into solar panels, and it is
Good! <b> But we forget to complete our own evolution as a human being. </b> We should do <u>something,</u> because the most exciting development for us
people are ahead of us. But only if we begin to get to know ourselves better and more. </p>
`,
};
