import sectionBrain from "./translations/sectionBrain";
import sectionHomoSapiens from "./translations/sectionHomoSapiens";
import sectionSun from "./translations/sectionSun";
import sectionWorld from "./translations/sectionWorld";
import sectionBrainNo from "./translations-no/sectionBrain";
import sectionHomoSapiensNo from "./translations-no/sectionHomoSapiens";
import sectionSunNo from "./translations-no/sectionSun";
import sectionWorldNo from "./translations-no/sectionWorld";

export const t =
  (lang: "no" | "en") =>
  (path: string): string => {
    const dict = {
      en: {
        back: "Back",
        collaborativePartners: "Collaborative Partners",
        colourAdventure: "Colour Adventure",
        history: "History",
        contactUs: "Contact us",
        personalityTestButton: "Personality test",
        personalityTest: {
          title: "Which colour are you?",
          text: "Click on the hat",
          href: "https://en.colourenergyeducation.com/Powerof7/personalitytest.html",
        },
        shopInformation: `
        The main idea for making a Colour Energy Shop has to do with human health in our time. And since GREEN energy is the same as BALANCE and CONTROL, we go for that.
      `,
        page: {
          home: {
            brain: sectionBrain,
            homoSapiens: sectionHomoSapiens,
            sun: sectionSun,
            world: sectionWorld,
          },
        },
      },
      no: {
        back: "Tilbake",
        collaborativePartners: "Samarbeids / Partnere",
        colourAdventure: "Farge Eventyr",
        history: "HISTORIEN VÅR",
        contactUs: "Kontakt oss",
        personalityTestButton: "Personlighets test",
        personalityTest: {
          title: "HVILKEN FARGE LIKER DU BEST?",
          text: "Klikk på hatten",
          href: "https://no.colourenergyeducation.com/powerof7/personlighetstest.html",
        },
        shopInformation: `
      Grunnlaget for å lage en FARGE ENERGY SHOP er vår helse, og siden GRØNN energi handler om BALANSE og KONTROLL over vårt eget LIV, så satset vi på det.
      `,
        page: {
          home: {
            brain: sectionBrainNo,
            homoSapiens: sectionHomoSapiensNo,
            sun: sectionSunNo,
            world: sectionWorldNo,
          },
        },
      },
    };

    return path
      .split(".")
      .reduce((o, i) => o[i], dict[lang]) as unknown as string;
  };
