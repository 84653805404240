import React from "react";

const Image: React.FC<any> = ({
  src,
  alt,
  style = {},
  width,
  height,
  className,
}) => (
  <>
    <img
      src={src}
      alt={alt}
      style={style}
      width={width}
      height={height}
      className={className}
    />

    {/* language=CSS */}
    <style jsx>{`
      img {
        display: block;
        width: ${"100%"};
        max-width: 1600px;
        margin: 0 auto 36px;
        border-radius: 4px;
      }
    `}</style>
  </>
);

export default Image;
