import React, { useContext, useEffect, useState } from "react";
import { t } from "./utils";

const languageStore = {
  set: (lang: "no" | "en") => localStorage.setItem("langcode", lang),
  get: () => localStorage.getItem("langcode"),
};

export const LanguageContext = React.createContext({
  lang: "no",
  translate: t("no"),
});

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider: React.FC = ({ children }) => {
  const [lang, setLang] = useState<"no" | "en">("no");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const detectedUserLang =
      params.get("lang") ||
      languageStore.get() ||
      (process.env.REACT_APP_NO_ONLY === "true"
        ? "no"
        : window.navigator.language);

    console.log(detectedUserLang);
    console.log({
      p: params.get("lang"),
      l: languageStore.get(),
      n: window.navigator.language,
    });
    const isNorwegian = detectedUserLang.toLowerCase().includes("no");
    setLang(isNorwegian ? "no" : "en");
    languageStore.set(isNorwegian ? "no" : "en");
  }, []);

  return (
    <LanguageContext.Provider value={{ lang, translate: t(lang) }}>
      {children}
    </LanguageContext.Provider>
  );
};
