/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./App.css";
import Image from "./components/image";
import allAboutColoursSrc from "./all-about-colors.jpg";
import educationSrc from "./shop-happy-hormones.png";
import educationEnSrc from "./shop-happy-hormones-en.png";
import personalityBlueSrc from "./personality-test-blue.png";
import personalityGreenSrc from "./personality-test-green.png";
import personalityIndigoSrc from "./personality-test-indigo.png";
import personalityOrangeSrc from "./personality-test-orange.png";
import personalityRedSrc from "./personality-test-red.png";
import personalityVioletSrc from "./personality-test-violet.png";
import personalityYellowSrc from "./personality-test-yellow.png";
import educationBackgroundSrc from "./bg-yellow2-texture.jpeg";
import therapeuticSrc from "./therapeutic1200.jpg";
import { Title } from "./components/title";
import { Slider } from "./components/Slider";
import { colors } from "./colors";
import { Nav, Type } from "./components/Nav";
import { ShopSlider } from "./components/ShopSlider";
import { useLanguage } from "./LanguageContext";
import { Helmet } from "react-helmet";

const fontSize = 100;

const useShouldDisplayBackButton = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("source") === "ce-international";
};

function Shop() {
  const personalityImages = [
    personalityBlueSrc,
    personalityGreenSrc,
    personalityIndigoSrc,
    personalityOrangeSrc,
    personalityRedSrc,
    personalityVioletSrc,
    personalityYellowSrc,
  ];
  const [personlityIndex, setPersonalityIndex] = useState(0);
  const { lang, translate } = useLanguage();
  const shouldDisplayBackBtn = useShouldDisplayBackButton();
  return (
    <div className="App">
      <Helmet>
        <title>Colour Energy Shop</title>
        <meta name="description" content="Official Colour Energy Shop" />
      </Helmet>
      <Nav
        menu={
          shouldDisplayBackBtn
            ? [
                {
                  type: Type.LINK,
                  href: "https://colourenergyinternational.com",
                  label: translate("back"),
                  selectedColor: colors.green,
                },
                { type: Type.DOT, color: colors.red },
                {
                  type: Type.LINK,
                  label: translate("contactUs"),
                  href: `https://colourenergyworld.com/${lang}/contact`,
                  selectedColor: colors.green,
                },
              ]
            : [
                {
                  type: Type.LINK,
                  label: translate("contactUs"),
                  href: `https://colourenergyworld.com/${lang}/contact`,
                  selectedColor: colors.green,
                },
              ]
        }
        white
        compact
      />
      <Title onlyGreen subtitle="ColourEnergyInternational.com presents">
        Colour Energy Shop
      </Title>
      <div className="content" style={{ backgroundColor: "#fff" }}>
        <div className="inner-content">
          <div className="entry-item">
            <a
              className="entry-link"
              href={`http://${
                lang === "no" ? "produkter" : "products"
              }.colourenergyshop.com/`}
            >
              Colour Energy Shop
            </a>
            <img
              src={lang === "no" ? educationSrc : educationEnSrc}
              className="entry-image"
            />
          </div>
          {/* <div className="entry-item">
            <a
              className="entry-link"
              href={`https://${lang}.colourenergyeducation.com/`}
            >
              All about colours
            </a>
            <img src={allAboutColoursSrc} className="entry-image" />
          </div> */}
          <div className="App-logo-container">
            <div className="App-logo" />
          </div>
          <div className="entry-item">
            <a className="entry-link" href={translate("personalityTest.href")}>
              {translate("personalityTestButton")}
            </a>
            <div className="image-wrapper">
              <img
                src={personalityImages[personlityIndex]}
                className="entry-image"
                onClick={() => setPersonalityIndex((personlityIndex + 1) % 7)}
              />
              <div className="image-content">
                <h3 className="image-title">
                  {translate("personalityTest.title")}
                </h3>
                <p className="image-text">
                  {translate("personalityTest.text")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="main-menu">
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#EA3223" }}
          href="https://colourenergyshop.com/collections/physical-body"
        >
          Physical
        </a>
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#EC5128" }}
          href="https://colourenergyshop.com/products/energy-blends"
        >
          Oils
        </a>
        <a
          className="main-menu__item"
          style={{
            backgroundColor: "#F5B942",
            color: colors.rainbow.indigo,
          }}
          href="https://colourenergyshop.com/collections/books-etc"
        >
          Books
        </a>
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#29591B" }}
          href="https://colourenergyshop.com/collections/natural-products"
        >
          Natur
        </a>
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#3682C5" }}
          href="https://colourenergyshop.com/collections/bathing"
        >
          Bathing
        </a>
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#203782" }}
          href="https://colourenergyshop.com/collections/spiritual-body"
        >
          Intuition
        </a>
        <a
          className="main-menu__item"
          style={{ backgroundColor: "#BC2B95" }}
          href="https://colourenergyshop.com/collections/spa-collection-skin-care"
        >
          Creativity
        </a>
      </div> */}
      <div className="information-box">{translate("shopInformation")}</div>
      <img src={therapeuticSrc} className="banner-img" />
      {/* <ShopSlider /> */}
      <style jsx>{`
        .title,
        .description {
          text-align: center;
        }

        .header .description {
          max-width: 1000px;
          margin-right: auto;
          margin-left: auto;
          font-size: 20px;
        }

        .row {
          max-width: 880px;
          margin: 80px auto 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }

        .card {
          padding: 18px 18px 24px;
          width: 220px;
          text-align: left;
          text-decoration: none;
          color: #434343;
          border: 1px solid #9b9b9b;
        }

        .card:hover {
          border-color: #067df7;
        }

        .card h3 {
          margin: 0;
          color: #067df7;
          font-size: 18px;
        }

        .card p {
          margin: 0;
          padding: 12px 0 0;
          font-size: 13px;
          color: #333;
        }

        .title-subsection {
          margin: 0;
          text-align: center;
          font-size: 32px;
          line-height: 36px;
          font-weight: 600;
          text-transform: uppercase;
        }

        :global(.img-header) {
          margin: 10px 0 !important;
          width: 100% !important;
          float: none !important;
        }

        .content {
          max-width: 100%;
          margin: 0 auto;
          padding: 0 20px;
        }

        .inner-content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .left-menu {
          display: block;
        }

        .menu-block {
          display: block;
          font-weight: 900;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          width: 450px;
          height: 450px;
        }

        .menu-block-img {
          filter: contrast(90%);
        }

        .banner {
          flex-grow: 0;
          display: block;
          width: 100%;
        }

        .banner-nav {
          appearance: none;
          border: none;
          background: none;
          font-size: 40px;
          color: rgba(0, 0, 0, 0.5);
          cursor: pointer;
        }

        .entry-link {
          display: flex;
          width: 100%;
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
          border-radius: 6px;
          background-color: ${colors.green};
          text-decoration: none;
          color: #fff;
          padding: 20px 10px;
          align-items: center;
          justify-content: center;
        }

        .entry-image {
          width: 100%;
          height: 270px;
          object-fit: contain;
          margin-top: 10px;
          border-radius: 6px;
          box-shadow: 0 1px 4px 8px rgba(0, 0, 0, 0.12);
        }

        .entry-image.with-background {
          background-image: url("${educationBackgroundSrc}");
        }

        .image-wrapper {
          position: relative;
          text-align: center;
        }

        .image-content {
          position: absolute;
          top: 10px;
          left: 20px;
          text-align: left;
        }

        .image-title {
          font-size: 16px;
          line-height: 19px;
          color: ${colors.green};
          margin-bottom: 1px;
          font-weight: 700;
        }

        .image-text {
          font-size: 14px;
          line-height: 16px;
          color: ${colors.green};
          font-weight: 400;
        }

        .entry-item {
          display: flex;
          width: 320px;
          height: 320px;
          flex-direction: column;
          align-items: center;
        }

        .main-menu {
          margin-top: 20px;
          display: grid;
          width: 100%;
          grid-template-columns: repeat(7, 1fr);
          grid-column-gap: 20px;
          padding: 30px 20px;
          z-index: 1;
        }

        .main-menu__item {
          padding: 15px 5px;
          font-weight: 700;
          font-size: 18px;
          font-style: italic;
          opacity: 1;
        }

        .banner-img {
          display: block;
          width: 80%;
          margin: 0 auto;
          z-index: 0;
          transform: translateY(-20px);
        }

        .information-box {
          display: block;
          width: auto;
          max-width: 1060px;
          margin: 20px auto 0;
          padding: 15px;
          font-size: 26px;
          z-index: 1;

          border: solid 1px ${colors.rainbow.green};
          color: ${colors.rainbow.green};
          box-sizing: border-box;
        }

        . @media screen and (max-width: 900px) {
          .container {
            max-width: 80%;
          }
        }
      `}</style>
    </div>
  );
}

export default Shop;
