import React from "react";

export default {
  title: "Planet Earth",
  text: `
<P>
Once upon a time we were warriors living in this beautiful world and we tried to find out who we were and where we were. We were the nature’s Earth people and lived essentially by <b> RED </b>
energy and we conquered the whole world on
 <b> RED </b> energy.
 <br />
 Today we need to use another type of energy to survive as Homo Sapiens. We need to use
 <b> INDIGO </b> energy to be able to understand the vibration of the Universal language.
 And the same power that LIFE contains, exist in our human body, and that power is the strongest energy resource we have on earth.
 <br />
 And our goal in Colour Energy is to introduce these friendly resources to the people on our Earth because as far as we have come  with our education as Humans , we have nevertheless failed to educate people in the most important subject; COLOURS.
</P>
<p >
 <B>
 Without the colours, it is difficult to understand why the rainbow is there and why the light contains all the colours?
 </B>
 <B>
 We need the colours to understand how we are and not least how we use the resources of the colours.
 </B>
 <B>
 We need to know WHO WE ARE so that we can become who we are meant to be. 
  </B>
</P>
    `,
};
